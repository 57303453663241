import React from 'react'
import { Link } from 'react-router-dom'
import './Tours.css'

function Tours({tours}) {
    return (
        <div className="tours" id="tours">
            <div className="tours-top"></div>
          <div className="tours-inner">
              <div className="title">
                  <h1>Aktiv Turlarımız</h1>
              </div>
              <div className="tours-container">
                {
                    tours.map((tour)=>(
                        (<div key={tour.id} className={`tour ${tour.id%2===0 && "row-reverse"}`}>
                            <div  className="tour-image">
                                <img src={`${tour.image}`} alt=""/>
                            </div>
                            <div className="tour-info">
                                <h3>{tour.name}</h3>
                                <p>{tour.info}</p>
                                <Link to={`/tours/${tour.link}`}>
                                    Ətraflı Məlumat
                                </Link>
                            </div>
                        </div>)
                    ))
                }
              </div>
          </div>
        </div>
    )
}

export default Tours

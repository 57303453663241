import React from 'react'
import './NotFound.css'

function NotFound() {
    return (
      <div className="not-found">
        <div className="not-found-inner">
          <h1>Bu səhifə mövcud deyil</h1>
          <a href="/#">Geri Qayıt</a>
        </div>
      </div>
    );
}

export default NotFound 

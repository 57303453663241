import { FlightTakeoff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import NotFound from './components/notfound/NotFound';

import Home from './screen/home/Home';
import Tour from './screen/tour/Tour';

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(()=>{
      fetch('/webData.json')
      .then((res)=>res.json())
      .then((data)=>setData(data.aze));
    },[]);

    useEffect(() => {
        if(data){
          setIsLoading(false);
        }
    }, [data])

    if(isLoading){
      return <div className="loading">
          <div className="loading-center">
                <FlightTakeoff />
                <p>KRE Travel</p>
          </div>
      </div>;
    }

  return (
    <div className="app">
      <Header data={data.menu} contact={data.main.contact} />
      <div className="main">
        <Switch>
          <Route exact path="/">
            <Home
              contact={data.main.contact}
              data={data.main}
              services={data.services}
              tours={data.tours}
            />
          </Route>
          <Route path="/tours/:link">
            <Tour contact={data.main.contact} tours={data.tours} />
          </Route>
          <Route path="*/:*">
            <NotFound />
          </Route>
        </Switch>
      </div>
      <Footer data={data.menu} contact={data.main.contact} />
    </div>
  );
}

export default App;


import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NotFound from '../../components/notfound/NotFound';
import './Tour.css'

function Tour({tours, contact}) {
    const { link } = useParams();
    const [tour, setTour] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(()=>{
        const newtour = tours.filter((tour)=>tour.link===link);
        if (newtour.length===1) {
          setTour(newtour[0]);
          setLoading(false);
        } else {
          setNotFound(true);
          setLoading(false);
        }
    }, [link])

    useEffect(()=>{
        if(tour){
            setLoading(false);
        }
    },[tour])

    if(loading){
        return <></>
    }

    if(notFound){
      return <NotFound />
    }

    return (
      <div className="tour-container">
        <div className="tour-inner">
          <div className="title">
            <h1>{tour.name}</h1>
          </div>
          <div className="tour-wrap">
            <div className="tour-wrap-image">
              <img src={tour.image} alt="" />
            </div>
            <div className="tour-wrap-info">
                <h3 className="info-name">{tour.name}</h3>
                <p className="info-info">{" "}{tour.info}</p>
                <p><span>Tarix:</span> {tour.date}</p>
                <p><span>Müddət:</span> {tour.duration}</p>
                <p><span>Qiymət:</span> {tour.price}</p>
              <div>
                <h4>Qiymətə Daxildir: </h4>
                <ul>
                  {tour["price-includes"] &&
                    tour["price-includes"].map((include) => (
                      <li key={include.id}>{include.info}</li>
                    ))}
                </ul>
              </div>
              {tour["places-to-visit"] && (
                <div>
                  <h4>Məkanlar: </h4>
                  <ul>
                    {tour["places-to-visit"].map((include) => (
                      <li key={include.id}>{include.info}</li>
                    ))}
                  </ul>
                </div>
              )}
              {tour.hotel && (<>
              <p>{"Otel : "}{tour.hotel.name}{"  "}
              {tour.hotel.link && 
                <a style={{color:"blue"}} href={tour.hotel.link}>..Otelə Baxmaq</a>
              }
              </p>
              </>)}
              {tour.note && (<><p>Qeyd: </p>
                              <ul>
                                {tour.note.map(note=>(
                                  <li key={note.id}>{note.text}</li>
                                ))}
                              </ul>
                            </>)
              }
              {tour.gathering && <p>Toplanış: {tour.gathering}</p>}
              {tour["move-out"] && <p>Çıxış: {tour["move-out"]}</p>}
              {tour.return && <p>Qayıdış: {tour.return}</p>}
              {tour.ps && <p>Qeyd: {tour.ps}</p>}

              <a href={`tel:${contact}`} className="tour-wrap-contact">
                  Reserv Et
              </a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Tour

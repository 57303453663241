import { FlightTakeoff, Phone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Button from '../button/Button';
import './Header.css';

function Header({data, contact}) {
    const [show, setShow] = useState(false);
    const [mobileSize, setMobileSize] = useState(false);

    const close = () => {
        setShow(false);
    }

    const showBottom =()=>{
        if(window.innerWidth < 768) {
            setMobileSize(true)
        }
        else {
            setMobileSize(false)
        }
    }

    window.addEventListener('resize', showBottom);

    useEffect(()=>{
        showBottom();
    },[]);

    return (
      <div className="header">
        <div className="header-inner">
          <nav className="nav">
            <a href="/#" className="nav-logo-link" onClick={close}>
              <div className="nav-logo">
                <FlightTakeoff />
                <p>KRE Travel</p>
              </div>
            </a>
            <ul className={`nav-list ${show && "show"}`}>
              {mobileSize && (
                <div className="nav-close" onClick={close}>
                  <div></div>
                </div>
              )}
              {data.map((link) => (
                <li key={link.id} onClick={close}>
                  <a href={`/#${link.link}`}>{link.name}</a>
                </li>
              ))}
              {mobileSize && (
                <li className="btn-list">
                  <Phone />
                  <a href={`tel:${contact}`}>Bizimle Əlaqə</a>
                </li>
              )}
            </ul>
            {!mobileSize && (
              <Button buttonSize="btn--medium" buttonStyle="btn--primary">
                <Phone />
                <a href={`tel:${contact}`}>Bizimle Əlaqə</a>
              </Button>
            )}
            <div className="nav-hamburger" onClick={() => setShow(true)}>
              <div></div>
            </div>
          </nav>
        </div>
      </div>
    );
}

export default Header

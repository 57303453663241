import React from 'react'
import './Home.css'
import Banner from '../../components/banner/Banner'
import Services from '../../components/services/Services'
import Tours from '../../components/tours/Tours'

function Home({data, services, tours, contact}) {
    return (
        <div className="home">
            <Banner />
            <Services services={services} contact={contact} />
            <Tours tours={tours} />
        </div>
    )
}

export default Home

import React from 'react'
import './Banner.css'
import Button from '../button/Button'

function Banner({imgPath}) {
    return (
        <div id="home" className="banner" style={{backgroundImage:'linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(/images/banner.jpg)'}}>
            <div className="banner-inner">
                <h1>KRE Travel sizin xidmətinizdədir. </h1>
                <Button buttonStyle="btn--outline" buttonSize="btn--large">
                    <a href="tel:">Bizimle Əlaqə</a>
                </Button>
            </div>
            <div className="banner-footer"></div>
        </div>
    )
}

export default Banner

import { FlightTakeoff, Phone } from '@material-ui/icons'
import { send } from 'emailjs-com';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from '../button/Button'
import './Footer.css'

function Footer({ data, contact }) {
    const [text, setText] = useState('Müraciətiniz');
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false)


    const handleSubmit=(event)=>{
        event.preventDefault();
        var pattern = new RegExp(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
        );

        if (
          name &&
          number &&
          text &&
          pattern.test(number) &&
          number.length >= 10
        ) {
          let data = {
            name: name,
            number: number,
            message: text,
          };
            const toSend = {
              name,
              number,
              text,
            };
            send(
              "service_rjjxmg7",
              "template_6lolxg5",
              toSend,
              "user_v8X6apuKRR3mTrs7rJ9gb"
            )
              .then((response) => {
                resetForm();
                setSuccess(true);
              })
              .catch((error) => {
                setError(true);
                alert(error.message);
                console.log(error.message);
              });
          
        } else {
          setError(true);
        } 
    }

    const resetForm = () => {
        setText("Müraciətiniz");
        setName("");
        setNumber("");
    }

    const handleName = (e) => {
        setError(false);
        setName(e.target.value);
    }
    
    const handleNumber = (e) => {
      setError(false);
      setNumber(e.target.value);
    };
    
    const handleText = (e) => {
      setError(false);
      setText(e.target.value);
    };
    

    return (
      <div className="footer" id="contact">
        {success && (
          <div className="success">
            <div className="success-inner">
              <p>
                Mesajınız uğurla göndərildi. Sizinlə ən qısa zamanda əlaqə
                saxlanılacaq.
              </p>
              <button onClick={() => setSuccess(false)}>OK</button>
            </div>
          </div>
        )}
        <div className="footer-inner">
          <div className="footer-left">
            <div className="footer-left-inner">
              <a href="/#">
                <div className="footer-logo">
                  <FlightTakeoff />
                  <p>KRE Travel</p>
                </div>
              </a>
              <Button buttonStyle="btn--outline">
                <Phone />
                <a href={`tel:${contact}`}>Bizimle Elaqe</a>
              </Button>
            </div>
            <div className="footer-info">
              <div>
                <p>
                  Sahibkar (Owner):{""}
                  <span>
                    Abbasov Elşad Əli
                  </span>
                </p>
              </div>
              <div>
                <p>
                  Əlaqə nömrəsi (Contact Number):{""}
                  <span>
                    <a href={`tel:${contact}`}>{contact}</a>
                  </span>
                </p>
              </div>
              <div>
                <p>
                  Mail ünvani (Email address):{""}
                  <span>info@kretravel.az</span>
                </p>
              </div>
              <div>
                <p>
                  Vöen (Tax Number): <span>2701521192</span>
                </p>
              </div>
              <div>
                <p>
                  Ünvan (Address):
                  <span>Mingəçevir şəh., Hərbi Şəhərcik, ev.6 mən.13</span>
                </p>
              </div>
            </div>
          </div>

          <div className="footer-contact">
            <h4>Bizə Yazın</h4>
            <form onSubmit={handleSubmit} className="footer-form" method="POST">
              <input
                type="text"
                placeholder="Adınız"
                value={name}
                onChange={handleName}
              />
              <input
                type="text"
                placeholder="Əlaqə nömrəsi"
                value={number}
                onChange={handleNumber}
              />
              <textarea
                name="task"
                value={text}
                onChange={handleText}
                id=""
                cols="30"
                rows="3"
              ></textarea>
              {error && <span className="error">Xanaları doldurun</span>}
              <button type="submit">Göndər</button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default Footer

import React from 'react'
import './Services.css'

import { Security, Apartment, AirportShuttle, CardTravel, Flight } from '@material-ui/icons'

const Icons = [<Flight />,<CardTravel />, <AirportShuttle />, <Apartment />, <Security />];

function Services({services, contact}) {

    return (
      <div className="services" id="services">
        <div className="services-inner">
          <div className="title">
            <h1>Xidmətlərimiz</h1>
          </div>
          <div className="services-container">
            {services.map((service) => (
              <div key={service.id} className="service">
                <div className="service-image">
                  {Icons[service.id-1]}
                </div>
                <div className="service-info">
                  <h3>{service.name}</h3>
                  <p>{service.description}</p>
                  <a href={`tel:${contact}`}>Əlaqə</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default Services

import React from 'react'
import './Button.css'

const Sizes = ['btn--medium', 'btn--large'];
const Styles = ['btn--primary', 'btn--outline'];

function Button({
    children,
    buttonSize,
    buttonStyle,
    onClick,
    type
}) {

    const btnStyle = Styles.includes(buttonStyle) ? buttonStyle : Styles[0];
    const btnSize = Sizes.includes(buttonSize) ? buttonSize : Sizes[0]

    return (
        <button className={`btn ${btnStyle} ${btnSize}`}>
            {children}
        </button>
    )
}

export default Button
